@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeueCyr-Black.eot');
    src: url('../fonts/HelveticaNeueCyr-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HelveticaNeueCyr-Black.woff') format('woff'),
    url('../fonts/HelveticaNeueCyr-Black.ttf') format('truetype') {}
    font-weight: 900;
    font-style: normal; }
@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeueCyr-Roman.eot');
    src: url('../fonts/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HelveticaNeueCyr-Roman.woff') format('woff'),
    url('../fonts/HelveticaNeueCyr-Roman.ttf') format('truetype') {}
    font-weight: 400;
    font-style: normal; }
@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeueCyr-Medium.eot');
    src: url('../fonts/HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HelveticaNeueCyr-Medium.woff') format('woff'),
    url('../fonts/HelveticaNeueCyr-Medium.ttf') format('truetype') {}
    font-weight: 500;
    font-style: normal; }
@font-face {
    font-family: 'Helvetica Neue Cyr Bold';
    src: url('HelveticaNeueCyr-Bold.eot');
    src: url('HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'),
    url('HelveticaNeueCyr-Bold.woff') format('woff'),
    url('HelveticaNeueCyr-Bold.ttf') format('truetype') {}
    font-weight: 600;
    font-style: normal; }


::-webkit-scrollbar-button {
    background-image: url('');
    background-repeat: no-repeat;
    width: 6px;
    height: 0px; }

::-webkit-scrollbar-track {
    background-color: #eee; }

::-webkit-scrollbar-thumb {
    /*border-radius: 4px*/
    background-color: #b50d1c;
    background-position: center;
    background-repeat: no-repeat; }

::-webkit-resizer {
    background-image: url('');
    background-repeat: no-repeat;
    width: 7px;
    height: 0px; }

::-webkit-scrollbar {
    width: 7px; }

.download-box {
    width: 100%;
    flex-direction: row;
    .download-el {
        align-items: center;
        margin-top: 30px;
        text-decoration: none;
        img {
            height: 30px; }
        &>div {
            margin-left: 20px;
            font-family: 'Roboto';
            div:nth-child(1) {
                font-weight: 500;
                color: #555; }
            div:last-child {
                font-weight: 400;
                color: #999; } } } }
.dovidnik-el {
    display: flex;
    flex-direction: column;
    flex: 0 0 25%;
    flex-wrap: wrap;
    padding: 5px 5px 30px 5px;
    text-decoration: none;
    .img-box {
        width: 100%;
        max-height: 160px;
        height: 160px;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    .dovidnik-name {
        text-transform: uppercase;
        margin-top: 10px;
        position: relative;
        color: #777;
        font-size: 18px;
        font-family: BebasNeueCyrillic; } }
.dovidnik-content {
    color: #999;
    font-size: 16px;
    line-height: 1.3;
    font-family: 'Roboto', sans-serif;
    img {
        width: 350px;
        float: left;
        margin-bottom: 20px;
        margin-right: 40px; }
    h3 {
        font-size: 18px;
        font-family: BebasNeueCyrillic;
        font-weight: normal;
        padding-left: 25px;
        strong {
            font-size: 20px;
            font-family: BebasNeueCyrillic;
            font-weight: normal !important; } } }


