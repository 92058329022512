.p-404-form-canvas {
    background: #fff; //#fbca00
    min-height: 100vh;

    .p-404-form-box {
        padding: 20px;

        .p-404-title {
            font-size: 24px;
            color: #212121;
            font-weight: 900;
            margin: 0;
            padding: 0; }


        .p-404-subtitile {
            font-size: 18px;
            max-width: 600px;
            width: 100%;
            line-height: 1.25;
            color: #212121;
            font-weight: 400;
            margin: 0;
            padding: 0 0 30px 0;
            b {
                font-size: 14px; } } } }
